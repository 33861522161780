.key {
	display: inline-block;
	vertical-align: middle;
	width: 50%;
}

.editentry {
	display: inline-block;
	width: 50%;
	font-style: italic;
}

.keyvaluepair {
	margin-bottom: 5pt;
}