div.entries {
	padding-top: 10pt;
	/* border-top: 1pt solid black; */
	width: 60%;
	margin: 0 auto;
}

input {
	background-color: #f6fd6b;
	border-style: inset;
	border-color: #082202;
	color: #082202;
}

.entriescount {
	width: 20%;
	margin: 0 auto;
	padding-top: 10pt;
	padding-bottom: 20pt;
}

.entry {
	padding-bottom: 0.5em;
}

.term {
	display: inline-block;
	font-weight: bold;
	color: feff74;
	vertical-align: top;
	width: 20%;
	font-variant: small-caps;
	font-size: 1.1em;
}

.definition {
	display: inline-block;
	width: 80%;
	/* font-style: italic; */
}