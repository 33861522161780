body {
	font-family: 'DejaVu Sans Light', Arial, Helvetica, sans-serif;
	background-color: #082202;
	color: #f6fd6b;
}

h1 {
	text-align: center;
}

div.searchbox {
	width: 20%;
	height: 30%;
	margin: 0 auto;
	padding: 30pt 0 30pt 0;
}
