.help {
	margin: 15pt auto;
	width: 30%;
	color: beige;
}

.show {
	display: block;
}

.hide {
	display: none;
}
