.lettersbar {
	margin: 0 auto;
	width: 60%;
	display: flex;
	justify-content: space-between;
}

.letter {
	font-size: 1.5em;
	cursor: pointer;
	display: inline-block;
	width: fit-content;
}

.active {
	font-size: 1.7em;
	color: white;
}